define("client/routes/lead-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      toCreate: function toCreate() {
        this.transitionTo('lead-create');
      },
      toEdit: function toEdit(lead) {
        this.transitionTo('lead-edit', lead);
      },
      toView: function toView(lead) {
        this.transitionTo('lead-view', lead);
      },
      toEditNewTab: function toEditNewTab(lead) {
        var url = this.router.generate('lead-edit', lead);
        window.open(url, '_blank');
      },
      toViewNewTab: function toViewNewTab(lead) {
        var url = this.router.generate('lead-view', lead);
        window.open(url, '_blank');
      }
    }
  });

  _exports.default = _default;
});