define("client/react/utilities/smart-form-2", ["exports", "@Harris-Barrick-Software/use-form"], function (_exports, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function useSmartForm2(_ref) {
    var schema = _ref.schema,
        defaultValues = _ref.defaultValues,
        disabledDateCoercionFields = _ref.disabledDateCoercionFields;

    if (!schema) {
      throw new Error("A schema must be provided to useSmartForm2");
    }

    return (0, _useForm.useForm)({
      mode: "all",
      criteriaMode: "all",
      resolver: (0, _useForm.yupResolver)(schema),
      defaultValues: defaultValues ? defaultValues : {},
      disabledDateCoercionFields: disabledDateCoercionFields
    });
  }

  var _default = useSmartForm2;
  _exports.default = _default;
});